import React, { useMemo, forwardRef } from "react";
import { Box, Input, Textarea, Text } from "@chakra-ui/react";

// borderType: "bottom" | "all"
// labelType: "label" | "placeholder"

const uniqueId = () => `${Date.now()}-${Math.floor(Math.random() * 10000)}`;

const FormControl = forwardRef(
  (
    {
      multiline = false,
      label,
      labelType = "label",
      borderRadius = "0",
      borderType = "bottom",
      value,
      onChange,
      height,
      width,
      ...rest
    },
    ref
  ) => {
    const inputId = useMemo(() => uniqueId(), []);

    const inputProps = useMemo(() => {
      const newProps = {
        variant: "unstyled",
        padding: "7px 12px",
        id: inputId,
        multiple: multiline,
        value: value,
        onChange: onChange,
        height: height,
        width: width,
      };

      if (label && "placeholder" === labelType) {
        newProps.placeholder = label;
      }

      if ("all" === borderType) {
        newProps.border = "solid 1px #E2E2E2";
        newProps.borderRadius = borderRadius;
      } else {
        newProps.borderBottom = "solid 2px #E2E2E2";
        newProps.borderRadius = 0;
        newProps.paddingLeft = 0;
      }

      return newProps;
    }, [
      multiline,
      value,
      onChange,
      labelType,
      label,
      borderType,
      borderRadius,
      height,
      width,
      inputId,
    ]);

    return (
      <Box>
        {label && "label" === labelType && (
          <label htmlFor={inputId}>
            <Text as="span" fontWeight="bold" fontSize="14px" lineHeight="17px">
              {label}
            </Text>
          </label>
        )}
        {multiline ? (
          <Textarea ref={ref} {...rest} {...inputProps} resize="none" />
        ) : (
          <Input ref={ref} {...rest} {...inputProps} />
        )}
      </Box>
    );
  }
);

export default FormControl;
